import Head from 'next/head'
import { useRouter } from 'next/router'
import LogoMobile from 'public/images/mobile-logo.svg'
import SignGlide from 'public/images/signature-glide.svg'
import SignNudge from 'public/images/signature-nudge.svg'
import SignQuantum from 'public/images/signature-quantum.svg'
import { useEffect, useState } from 'react'
import useAccount from 'src/auth/useAccount'
import FirebaseAuth, { AuthProvider } from 'src/components/Auth/FirebaseAuth'
import Logo from 'src/components/Logo'
import { getBaseUrl } from 'src/helpers'
import { appPageURL } from 'src/initAuth'
import { useCreatePageTracker } from 'src/tracking/hooks/useCreatePageTracker'

import OptimizedImage from '../OptimizedImage'

export const getAuthUrlWithRedirect = (path: string) =>
  `/auth/?redirect=${encodeURIComponent(path)}`

const vercelPreviewUrlRegex =
  /^flow-builder-([0-9a-zA-Z-]+)-arcadehq\.vercel\.app$/

interface Props {
  providers: AuthProvider[]
}

const AuthPage = ({ providers }: Props) => {
  const account = useAccount()
  const router = useRouter()
  const [authRequestCompleted, setAuthRequestCompleted] = useState(false)

  useEffect(() => {
    document.addEventListener('next-firebase-auth:authRequestCompleted', () => {
      setAuthRequestCompleted(true)
    })
  }, [])

  let redirect: string | null = null
  let requireLoginToAccessFlow: string | null = null
  if (router.query) {
    if (typeof router.query.redirect === 'string') {
      redirect = router.query.redirect
    }
    if (typeof router.query.requireLoginToAccessFlow === 'string') {
      requireLoginToAccessFlow = router.query.requireLoginToAccessFlow
    }
  }

  const isVercelPreview = vercelPreviewUrlRegex.test(window.location.host)

  useCreatePageTracker('Login', {})

  useEffect(() => {
    window?.Intercom?.('shutdown')

    if (!router) {
      return
    }

    if (isVercelPreview) {
      // If user is not logged in, and this is a Vercel preview deployment,
      // redirect to a special route that associates our custom preview domain
      // to the Vercel deployment and redirect back to it, so we can properly
      // authenticate there.
      void router.push('/api/vercel/preview')
      return
    }

    if (!authRequestCompleted) {
      // Do not redirect before the auth request (`/api/login`) that sets
      // cookies has been completed, because we need cookies to be set before
      // redirecting to the next page.
      return
    }

    if (account.isLoggedIn && account.user.emailVerified) {
      // If user is already logged in, redirect based on URL parameter (or else to the default page)
      if (
        redirect &&
        (redirect.startsWith('/') || redirect.startsWith(getBaseUrl())) // Don't allow redirects outside of the app
      ) {
        void router.push(redirect)
      } else {
        void router.push(appPageURL)
      }
    }
  }, [
    authRequestCompleted,
    account.isLoggedIn,
    account.user.emailVerified,
    router,
    redirect,
    isVercelPreview,
  ])

  if (account.isLoggedIn || isVercelPreview) {
    return null
  }

  return (
    <>
      <Head>
        <title>Welcome to Arcade</title>
      </Head>
      <div className='relative flex flex-col items-center w-full justify-between h-screen bg-white text-gray-900'>
        <div className='flex flex-col items-center justify-center w-full h-full py-8 px-4'>
          <div className='flex flex-col items-center'>
            <Logo className='h-6 hidden sm:block mx-auto' square={false} />
            <LogoMobile className='block sm:hidden mx-auto w-8 h-8' />
            <h1 className='text-2xl font-bold mt-12 text-center max-w-[280px]'>
              Your Arcade is only a few minutes away
            </h1>
            {requireLoginToAccessFlow && (
              <div className='text-lg mt-2'>
                Please sign-in to view the demo.
              </div>
            )}
            <FirebaseAuth providers={providers} />
          </div>
        </div>

        <div className='relative flex flex-col items-center justify-center w-full min-h-[140px] py-8 bg-gradient-to-b from-transparent to-gray-100'>
          <div className='auth-carousel carousel-start absolute bottom-8 origin-top flex flex-col items-center justify-center w-full px-4 max-w-[420px]'>
            <h2 className='text-xs sm:text-sm leading-5 font-semibold text-center opacity-90'>
              Arcade is probably one of the most important ways for us to
              showcase the product and allow our web visitors and prospects to
              self-educate even before they jump into the product.
            </h2>
            <div className='flex flex-col justify-center items-center gap-2 sm:gap-3 mt-4 sm:mt-6'>
              <div className='flex flex-row items-center justify-center gap-3'>
                <div className='flex relative shadow-menuNoRing w-8 h-8 rounded-full overflow-hidden'>
                  <OptimizedImage
                    src='/images/avatar-nudge.png'
                    alt='Danielle Russell'
                    width={32}
                    height={32}
                    layout='fixed'
                  />
                </div>
                <SignNudge className='h-5 opacity-90' />
              </div>
              <div className='text-xs opacity-60 text-center'>
                VP, Product Management and Marketing @ Nudge Security
              </div>
            </div>
          </div>
          <div className='auth-carousel carousel-1 absolute bottom-8 origin-top flex flex-col items-center justify-center w-full px-4 max-w-[420px]'>
            <h2 className='text-xs sm:text-sm leading-5 font-semibold text-center opacity-90'>
              We had been looking for a way to show the product that felt
              interactive and alive, and we hadn’t found it, so we just weren’t
              doing it.
            </h2>
            <div className='flex flex-col justify-center items-center gap-2 sm:gap-3 mt-4 sm:mt-6'>
              <div className='flex flex-row items-center justify-center gap-3'>
                <div className='flex relative shadow-menuNoRing w-8 h-8 rounded-full overflow-hidden'>
                  <OptimizedImage
                    src='/images/avatar-glide.png'
                    alt='Cait Levin'
                    width={32}
                    height={32}
                    layout='fixed'
                  />
                </div>
                <SignGlide className='h-5 opacity-90' />
              </div>
              <div className='text-xs opacity-60 text-center'>
                Education Lead @ Glide
              </div>
            </div>
          </div>
          <div className='opacity-0 auth-carousel carousel-2 absolute bottom-8 origin-top flex flex-col items-center justify-center w-full px-4 max-w-[420px]'>
            <h2 className='text-xs sm:text-sm leading-5 font-semibold text-center opacity-90'>
              Arcade allowed us to have a much more focused narrative to tell
              the story we wanted.
            </h2>
            <div className='flex flex-col justify-center items-center gap-2 sm:gap-3 mt-4 sm:mt-6'>
              <div className='flex flex-row items-center justify-center gap-3'>
                <div className='flex relative shadow-menuNoRing w-8 h-8 rounded-full overflow-hidden'>
                  <OptimizedImage
                    src='/images/avatar-quantum.png'
                    alt='Trevor Pyle'
                    width={32}
                    height={32}
                    layout='fixed'
                  />
                </div>
                <SignQuantum className='h-5 opacity-90' />
              </div>
              <div className='text-xs opacity-60 text-center'>
                Senior Director of Product Marketing & Strategy @ Quantum Metric
              </div>
            </div>
          </div>
          <div className='opacity-0 auth-carousel carousel-3 absolute bottom-8 origin-top flex flex-col items-center justify-center w-full px-4 max-w-[420px]'>
            <h2 className='text-xs sm:text-sm leading-5 font-semibold text-center opacity-90'>
              Arcade is probably one of the most important ways for us to
              showcase the product and allow our web visitors and prospects to
              self-educate even before they jump into the product.
            </h2>
            <div className='flex flex-col justify-center items-center gap-2 sm:gap-3 mt-4 sm:mt-6'>
              <div className='flex flex-row items-center justify-center gap-3'>
                <div className='flex relative shadow-menuNoRing w-8 h-8 rounded-full overflow-hidden'>
                  <OptimizedImage
                    src='/images/avatar-nudge.png'
                    alt='Danielle Russell'
                    width={32}
                    height={32}
                    layout='fixed'
                  />
                </div>
                <SignNudge className='h-5 opacity-90' />
              </div>
              <div className='text-xs opacity-60 text-center'>
                VP, Product Management and Marketing @ Nudge Security
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AuthPage
