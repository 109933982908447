import GoogleLogo from 'public/images/google.svg'
import MicrosoftLogo from 'public/images/microsoft.svg'
import { MouseEventHandler, useRef } from 'react'

interface Props {
  onClick: MouseEventHandler<HTMLButtonElement>
}

export function GoogleLoginButton({ onClick }: Props) {
  return (
    <button
      type='button'
      className='text-gray-900 shadow-menu hover:bg-gray-50 rounded-lg p-4 w-full max-w-[350px] transition-colors bg-white h-14'
      onClick={onClick}
    >
      <div className='flex items-center justify-center gap-2.5'>
        <GoogleLogo className='h-5 w-5 shrink-0' />
        <div className='text-base font-semibold'>Continue with Google</div>
      </div>
    </button>
  )
}

export function MicrosoftLoginButton({ onClick }: Props) {
  return (
    <button
      type='button'
      className='text-gray-900 shadow-menu hover:bg-gray-50 rounded-lg p-4 w-full max-w-[350px] transition-colors bg-white h-14'
      onClick={onClick}
    >
      <div className='flex items-center justify-center gap-2.5'>
        <MicrosoftLogo className='h-5 w-5 shrink-0' />
        <div className='text-base font-semibold'>Continue with Microsoft</div>
      </div>
    </button>
  )
}

export function SsoLoginButton({ onClick }: Props) {
  return (
    <button
      type='button'
      className='text-gray-900 shadow-menu hover:bg-gray-50 rounded-lg p-4 w-full max-w-[350px] transition-colors bg-white h-14'
      onClick={onClick}
    >
      <div className='flex items-center justify-center gap-2'>
        <div className='text-base font-semibold'>Continue with SSO</div>
      </div>
    </button>
  )
}

export function EmailLink({ onClick }: Props) {
  const emailRef = useRef<HTMLInputElement>(null)
  const formRef = useRef<HTMLFormElement>(null)
  return (
    <form
      ref={formRef}
      className='flex flex-col gap-2 w-full'
      onSubmit={evt => {
        evt.preventDefault()
        evt.stopPropagation()
        const mouseEvt = evt as unknown as React.MouseEvent<HTMLButtonElement>
        onClick({
          ...mouseEvt,
          currentTarget: {
            ...mouseEvt.currentTarget,
            value: emailRef.current!.value || '',
          },
        })
      }}
    >
      <input
        ref={emailRef}
        className='w-full p-4 border border-gray-200 rounded-lg'
        type='email'
        placeholder='Enter your email'
      />
      <button
        type='submit'
        className='text-gray-900 shadow-menu hover:bg-gray-50 rounded-lg p-4 w-full max-w-[350px] transition-colors bg-white h-14'
      >
        <div className='flex items-center justify-center gap-2'>
          <div className='text-lg font-semibold'>Continue</div>
        </div>
      </button>
    </form>
  )
}
